.report-head {
  p {
    text-transform: uppercase;
    font-size: rem-calc(28);
    letter-spacing: -1.14px;
    font-weight: 300;
    margin-bottom: $spacing * 0.25;
    line-height: 1.1;
    @media #{$medium-up} {
      font-size: rem-calc(30);
    }
    @media #{$large-up} {
      font-size: rem-calc(32);
    }
  }
  span {
    font-weight: bold;
    letter-spacing: -1.18px;
  }
  time {
    display: block;
    font-size: rem-calc(18);
    font-weight: 300;
    letter-spacing: -0.64px;
  }
}
