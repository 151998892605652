.js body {
    @media screen and (min-width: $sticky-width) {
        padding-top: 0;
    }
}

// grups
.col-nom {
    @media screen and (min-width: 40em) {
        @include grid-column($columns: 12);
    }
    @media #{$medium-up} {
        @include grid-column($columns: 5);
    }
}
.col-info {
    @media screen and (min-width: 40em) {
        @include grid-column($columns: 12);
    }
    @media #{$medium-up} {
        @include grid-column($columns: 7);
    }
}

.multicol {
    @media screen and (min-width: 43em) {
        column-fill: unset;
    }
    &__group {
        break-inside: avoid;
    }
}

.btns-wrapper {
    @media screen and (min-width: 50em) {
        display: flex;
        margin: -$spacing * 0.5;
        margin-top: $spacing * 2;
    }
    > * {
        margin: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media screen and (min-width: 50em) {
            flex-basis: 33.333%;
            margin: $spacing * 0.5;
        }
        + * {
            margin-top: $spacing;
            @media screen and (min-width: 50em) {
                margin-top: $spacing * 0.5;
            }
        }
    }
}
