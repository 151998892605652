.report-container {
  .js & {
    height: 30em;
    margin-bottom: $spacing;
    overflow: hidden;
    position: relative;
    &::after {
      content: "";
      text-align: right;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 1.5em;
      background: linear-gradient(to right, rgba(255, 255, 255, 0), #f1f1f1 50%);
    }
  }
  &.open {
    height: auto;
    &::after {
      display: none;
    }
  }
  .btn {
    margin-bottom: 0;
  }
  > * {
    line-height: 1.5em;
  }
}