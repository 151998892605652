.projects-list {
  @include undolist;
  list-style: none;
  @media screen and (min-width: 50em) {
    display: flex;
    flex-wrap: wrap;
    margin: -$spacing * 0.5;
  }
  > * {
    margin: 0;
    + * {
      margin-top: $spacing * 2;
    }
  }
  li {
    border-left: 1px solid #979797;
    padding-left: $spacing;
    @media screen and (min-width: 50em) {
      margin: $spacing * 0.5;
      margin-bottom: $spacing * 2;
      flex-basis: calc(50% - 1.5rem);
    }
    > * {
      margin: 0;
      + * {
        margin-top: $spacing * 0.75;
      }
    }
    &:last-child {
      @media screen and (min-width: 50em) {
        margin-bottom: $spacing * 2;
      }
    }
  }
  h3 {
    font-size: rem-calc(18);
    font-weight: bold;
    margin: 0;
    // color: #2c77ae;
  }
  p {
    font-size: rem-calc(14);
  }
  a {
    @include hover;
  }
}
