h2,
.t2 {
    font-weight: bold;
    @media #{$large-up} {
        font-size: rem-calc(28);
    }
}
h3,
.h3 {
    .section--report & {
        font-weight: bold;
        margin-top: $spacing*3;
        margin-bottom: $spacing*2;
        &:after {
            content: '';
            display: block;
            margin-top: $spacing*.5;
            height: 1px;
            width: $spacing*3;
            background-color: #000;
        }
    }
}
h4,
.h4 {
    .section--report & {
        margin-top: $spacing*2;
        font-size: rem-calc(17);
    }
}
p {
    .section--report & {
        font-size: rem-calc(16);
        font-weight: normal;
    }
}

