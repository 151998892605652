.home-top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // background-image: url("img/bg-top-home2.jpg");
  // @media #{$retina} {
  //   background-image: url("img/bg-top-home2@2x.jpg");
  // }
  background-color: $primary-color;
  h1 {
    font-size: rem-calc(28);
    font-weight: bold;
    margin: $spacing * 2 0;
    color: #fff;
    text-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    span {
      font-size: rem-calc(45);
      font-weight: 300;
      display: block;
      @media #{$medium-up} {
        font-size: rem-calc(50);
      }
      @media #{$large-up} {
        font-size: rem-calc(68);
      }
    }
  }
  &--overlay {
    &:before {
      background-color: transparent;
      background-image: linear-gradient(to right, #3185c2, transparent);
    }
  }
}
.h-actualitat h2 {
  text-align: left;
  font-weight: 700;
}
.h-apartats-recerca {
  li {
    a {
      padding-left: rem-calc(100);
    }
    &:first-child img {
      left: 2.25rem;
    }
    &:nth-child(2) img {
      left: 2.5rem;
    }
    &:nth-child(3) img {
      left: 1.85rem;
    }
  }
}
.assaigs-container {
  background-image: url("img/mascarilla-gel-hidroalcoholic-hospital-sant-joan-deu-barcelona.jpg");
  @media #{$retina} {
    background-image: url("img/mascarilla-gel-hidroalcoholic-hospital-sant-joan-deu-barcelona@2x.jpg");
  }
}
