.main-header {
  @media screen and (min-width: $sticky-width) {
        height: auto;
        transition: none;
        .js & {
            position: static;
        }
    }
  .container {
        @media screen and (min-width: 50em) {
            margin-bottom: $spacing;
        }
        @media #{$medium-up} {
            margin-top: $spacing;
        }
    }

    &.landing{
        padding-bottom: 1rem;
    @media (max-width: 768px) {
            padding-top: 2.5rem !important;
            padding-bottom: 0rem;
        }
    }
}
a.contacte {
    float: none;
    display: block;
    text-align: center;
}
