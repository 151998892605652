.grid-list {
  // https://kidscorona.irsjd.org/projects/
  &--kids-projects {
    > * {
      &:nth-child(4n + 1),
      &:nth-child(4n + 2),
      &:nth-child(4n + 3) {
        background-color: $box-1;
      }
      &:nth-child(4n + 4) {
        background-color: $box-2;
      }
    }
  }
}
